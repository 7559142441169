import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { firebase } from "../../utils/firebase"
import { setValToLS } from "../../utils"
import S from "./styled"

const Signout = () => {
  const [loading] = useState(true)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            setValToLS("clepbcbaAdmin", null, true)
            setValToLS("clepbcbaAdminToken", null, true)
            navigate("/signin")
          })
          .catch(error => {
            setValToLS("clepbcbaAdmin", null, true)
            setValToLS("clepbcbaAdminToken", null, true)
            navigate("/signin")
          })
      } else {
        setValToLS("clepbcbaAdmin", null, true)
        setValToLS("clepbcbaAdminToken", null, true)
        navigate("/signin")
      }
    })
  }, [])

  return (
    <S.SectionContainer>
      {loading ? <div>Signing out...</div> : <div />}
    </S.SectionContainer>
  )
}

export default Signout
