import styled from "styled-components"

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > a:first-child > span {
    padding-left: 7.5px;
  }

  & span {
    font-weight: 600;
  }

  & > span.spacer {
    padding-left: 7.5px;
    padding-right: 7.5px;
    color: var(--working);
    font-weight: normal;
  }
`

export const Form = styled.form`
  width: 100%;
  padding: 7.5px 15px 15px 15px;

  & > div:not(:first-child) {
    margin-top: 15px;
  }
`

export const FormRow1 = styled.div`
  width: 100%;
`

export const FormRow2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;

  @media only screen and (max-width: 649px) {
    grid-template-columns: 1fr;
  }

  &.first-smaller {
    grid-template-columns: 0.4fr 1fr;
  }

  & div.empty {
    display: none;
  }
`

export const FormRow3 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;

  @media only screen and (max-width: 649px) {
    grid-template-columns: 1fr;
  }

  &.first-larger {
    grid-template-columns: 1.5fr 1fr 1fr;
  }

  & div.empty {
    display: none;
  }
`

export const FormMsgSubmit = styled.div`
  padding-top: 7.5px;
  width: 100%;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  & th,
  & td {
    border: 1px solid var(--border);
    padding: 7.5px;
    font-size: 0.9rem;
  }

  & th {
    background-color: var(--bg);
  }

  & th.left {
    text-align: left;
  }

  & td.center {
    text-align: center;
  }

  & th.right,
  & td.right {
    text-align: right;
  }

  & td.add button {
    border: 1px solid var(--blue);
    padding: 5px 10px;
  }

  & tfoot th {
    background: #fff;
  }

  & .working {
    color: var(--working);
  }
`
