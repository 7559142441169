import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import { storage } from "../../utils/firebase"
import { API_URL } from "../../utils"
import SelectOptGrp from "../../components/Select/optgroup"
import Input from "../../components/Input"
import File from "../../components/File"
import Msg from "../../components/Msg"
import Submit from "../../components/Submit"
import { Form, FormRow1, FormRow2, FormMsgSubmit } from "../../styles/styled"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronCircleRight,
  faExternalLinkSquare,
  faArrowSquareDown,
  faCheckCircle,
  faTimesCircle,
  faFilePdf,
  faFileAudio,
  faFileVideo,
  faTvAlt,
  faCircleNotch,
} from "@fortawesome/pro-light-svg-icons"

S.Form = Form
S.FormRow1 = FormRow1
S.FormRow2 = FormRow2
S.FormMsgSubmit = FormMsgSubmit

function fileName(str, icon) {
  if (icon === "audio") {
    return "course-audio"
  } else if (icon === "video") {
    return "course-video"
  } else {
    const split = str.split(" / ")
    return split[0].replace(/\s+/g, "-").toLowerCase()
  }
}

const AssetsForm = ({ data, token, success }) => {
  const [downloading, setDownloading] = useState("")
  const [names, setNames] = useState({})
  const [file, setFile] = useState(null)
  const [progress, setProgress] = useState(0)
  const [status, setStatus] = useState(5)
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})

  /*
    0 - error
    1 - complete (uploaded)
    10 - upload complete, encoding stream
    2 - initializing
    3 - initialized
    4 - uploading
    5 - idle
  */

  console.log(data)

  const formElement = useRef(null)

  const handleUpdate = (name, value) => {
    if (name === "type" && value === "description") {
      setNames(names => ({
        ...names,
        [name]: value,
        name: "Course Description",
      }))
    } else if (
      name === "type" &&
      (value === "support1" || value === "support2")
    ) {
      setNames(names => ({
        ...names,
        [name]: value,
        name: "",
      }))
    } else if (name === "type" && value === "audio") {
      setNames(names => ({
        ...names,
        [name]: value,
        name: "Course Audio (MP3)",
      }))
    } else if (name === "type" && value === "video") {
      setNames(names => ({
        ...names,
        [name]: value,
        name: "Course Video (MP4)",
      }))
    } else {
      setNames(names => ({ ...names, [name]: value }))
    }
  }

  const handleDownload = (url, filename) => {
    if (downloading) {
      return
    } else {
      setDownloading(url)

      fetch(url, {
        headers: {},
      })
        .then(res => res.blob())
        .then(blob => {
          const blobUrl = URL.createObjectURL(blob)
          const a = document.createElement("a")
          a.href = blobUrl
          a.download = filename || "Download"
          a.click()
          URL.revokeObjectURL(blobUrl)
          setDownloading("")
          return a
        })
    }
  }

  const handleInitialize = e => {
    if (
      (names.type === "support1" || names.type === "support2") &&
      !names.name
    ) {
      setMsg({
        type: "error",
        text: "Please enter a document name.",
      })
      setErrors(["name"])

      return false
    }

    if (e.target.files[0]) {
      const fileData = e.target.files[0]
      setStatus(2)
      setMsg({
        type: "working",
        text: "",
      })
      const reader = new FileReader()

      reader.onload = loadEvt => {
        if (reader.readyState === 2) {
          if (fileData.size > 1999999999) {
            setStatus(0)
            setMsg({
              type: "error",
              text: `File upload size limited to around 1.75 GB.`,
            })
          } else if (
            (names.type === "description" &&
              fileData.type === "application/pdf") ||
            (names.type === "support1" &&
              fileData.type === "application/pdf") ||
            (names.type === "support2" &&
              fileData.type === "application/pdf") ||
            (names.type === "audio" && fileData.type === "audio/mpeg") ||
            (names.type === "video" && fileData.type === "video/mp4")
          ) {
            setFile(fileData)
            setStatus(3)
            setMsg({
              type: "notice",
              text: `File initialized (${fileData.type}${
                fileData.size ? `, ${fileData.size}` : ""
              }), click below to upload.`,
            })
          } else {
            setStatus(0)
            setMsg({
              type: "error",
              text: "Error initializing file, check the file and format (PDF, MP3 or MP4 accepted).",
            })
          }
        }
      }

      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setStatus(4)
    setMsg({})
    let fileName = ""
    if (
      names.type === "description" ||
      names.type === "support1" ||
      names.type === "support2"
    ) {
      fileName = `${names.name.replace(/\s+/g, "-").toLowerCase()}.pdf`
    } else if (names.type === "audio") {
      fileName = `course-audio.mp3`
    } else if (names.type === "video") {
      fileName = `course-video.mp4`
    }

    const ref = storage.ref().child(`courses/${data.courseId}/${fileName}`)
    const uploadTask = ref.put(file)
    uploadTask.on(
      "state_changed",
      snapshot => {
        let step = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgress(step)
        setMsg({
          type: "working",
          text: `${snapshot.bytesTransferred} / ${snapshot.totalBytes}`,
        })

        /*
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING:
              console.log("Upload is running");
              break;
          }
        */
      },
      error => {
        console.log("error")
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadUrl => {
          if (
            names.type === "description" ||
            names.type === "support1" ||
            names.type === "support2" ||
            names.type === "audio"
          ) {
            handleUpdateFirestore(downloadUrl)
          } else if (names.type === "video") {
            handleAddCloudflare(downloadUrl)
          }
        })
      }
    )
  }

  const handleUpdateFirestore = async (downloadUrl, cfstreamId = "") => {
    const url = new URL(`${API_URL}/auth/courseassets`)
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          ...names,
          courseId: data.courseId,
          downloadUrl,
          size: file.size,
          cfstreamId,
          token,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      const json = await response.json()
      handleResponse(json, null, cfstreamId)
    } catch (error) {
      handleResponse(null, error, cfstreamId)
    }
  }

  const handleResponse = (json, error, cfstreamId) => {
    if (json) {
      if (json.resp === 1) {
        formElement.current.reset()
        setFile(null)
        setNames({
          //fine for either case since want to clear names anyway
          cfstreamId,
        })
        setStatus(cfstreamId ? 10 : 1)
        success(json.text)
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  const handleAddCloudflare = async downloadUrl => {
    const url = new URL(`${API_URL}/auth/cfstreamadd`)
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          courseId: data.courseId,
          downloadUrl,
          token,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        handleUpdateFirestore(downloadUrl, json.cfstreamId)
      } else {
        setStatus(0)
        setMsg({
          type: "error",
          text: "An error has occurred.",
        })
      }
    } catch (error) {
      setStatus(0)
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <S.Grid>
      <S.Form
        method="post"
        action="/"
        onSubmit={e => handleSubmit(e)}
        ref={formElement}
      >
        <S.FormRow1>
          <S.Table3>
            <thead>
              <tr>
                <th>Status</th>
                <th className="left">Media Asset</th>
                <th>View</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {data.assets.map((item, index) => {
                return (
                  <tr key={index}>
                    <td
                      className={
                        item.status ? "center success" : "center error"
                      }
                    >
                      <FontAwesomeIcon
                        icon={item.status ? faCheckCircle : faTimesCircle}
                        size="lg"
                      />
                    </td>
                    <td>
                      <div>
                        <span className={item.status ? "" : "working"}>
                          {item.name}
                        </span>
                        {item.icon && (
                          <FontAwesomeIcon
                            icon={
                              item.icon === "audio"
                                ? faFileAudio
                                : item.icon === "video"
                                ? faFileVideo
                                : faFilePdf
                            }
                            size="lg"
                          />
                        )}
                      </div>
                    </td>
                    <td className="center">
                      {item.stream ? (
                        <Link
                          to={`/admin/stream/${data.courseId}`}
                          state={{
                            courseId: data.courseId,
                            courseName: data.name,
                          }}
                        >
                          <FontAwesomeIcon icon={faTvAlt} size="2x" />
                        </Link>
                      ) : item.url ? (
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <FontAwesomeIcon
                            icon={faExternalLinkSquare}
                            size="2x"
                          />
                        </a>
                      ) : null}
                    </td>
                    <td className="center">
                      {item.url && (
                        <button
                          type="button"
                          className="link"
                          style={{
                            color:
                              downloading === item.url
                                ? "var(--working)"
                                : "inherit",
                          }}
                          onClick={
                            downloading === item.url
                              ? e => e.preventDefault()
                              : () =>
                                  handleDownload(
                                    item.url,
                                    fileName(item.name, item.icon)
                                  )
                          }
                        >
                          {downloading === item.url ? (
                            <FontAwesomeIcon
                              icon={faCircleNotch}
                              size="2x"
                              spin={true}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faArrowSquareDown}
                              size="2x"
                            />
                          )}
                        </button>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </S.Table3>
        </S.FormRow1>
        <S.FormRow2>
          <SelectOptGrp
            name="type"
            label="Select Media Type"
            reqd={true}
            autocomplete="off"
            data={[
              {
                label: "Publically available",
                data: [
                  { value: "description", name: "Course Description (PDF)" },
                ],
              },
              {
                label: "Only available after purchase",
                data: [
                  { value: "support1", name: "Support Document 1 (PDF)" },
                  { value: "support2", name: "Support Document 2 (PDF)" },
                  { value: "audio", name: "Audio Asset (MP3)" },
                  { value: "video", name: "Video Asset (MP4)" },
                ],
              },
            ]}
            update={handleUpdate}
            errors={errors}
          />

          <div className="empty" />
        </S.FormRow2>

        {names.type && (
          <S.FormRow2>
            {(names.type === "support1" || names.type === "support2") && (
              <Input
                name="name"
                label="Document Name"
                reqd={true}
                autocomplete="off"
                update={handleUpdate}
                errors={errors}
              />
            )}

            <File
              name="file"
              label="Upload File"
              reqd={true}
              autocomplete="off"
              update={handleInitialize}
              errors={errors}
              selected={file ? true : false}
            />

            {names.type !== "support1" && names.type !== "support2" && (
              <div className="empty" />
            )}
          </S.FormRow2>
        )}

        {status === 4 && (
          <S.FormRow1>
            <S.UploadingContainer>
              {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((item, index) => {
                return (
                  <div
                    key={index}
                    className={item <= progress ? "complete" : ""}
                  />
                )
              })}
            </S.UploadingContainer>
          </S.FormRow1>
        )}

        <S.FormMsgSubmit>
          {msg.type && <Msg data={msg} />}
          {msg.type === "notice" && (
            <Submit name="Upload Course Media" icon={faChevronCircleRight} />
          )}
        </S.FormMsgSubmit>
      </S.Form>
    </S.Grid>
  )
}

export default AssetsForm
