import React, { useState, useRef } from "react"
import { API_URL, isFieldError } from "../../utils"
import Input from "../../components/Input"
import Select from "../../components/Select"
import Msg from "../../components/Msg"
import Submit from "../../components/Submit"
import {
  Form,
  FormRow1,
  FormRow2,
  FormRow3,
  FormMsgSubmit,
} from "../../styles/styled"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronCircleRight,
  faTrashAlt,
  faPlusCircle,
} from "@fortawesome/pro-light-svg-icons"

S.Form = Form
S.FormRow1 = FormRow1
S.FormRow2 = FormRow2
S.FormRow3 = FormRow3
S.FormMsgSubmit = FormMsgSubmit

const DetailsForm = ({ data, token, categories, certifCodes, success }) => {
  const [names, setNames] = useState({ certif: [], ...data })
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})

  const formElement = useRef(null)

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleRemove = index => {
    let newArray = names.certif
    newArray.splice(index, 1)
    handleUpdate("certif", newArray)
  }

  const handleClick = e => {
    e.preventDefault()
    let newArray = names.certif
    if (names.certifCode && names.certifHours) {
      newArray.push({
        credit: names.certifCode,
        hours: names.certifHours,
      })
      handleUpdate("certif", newArray)
      handleUpdate("certifCode", "")
      handleUpdate("certifHours", "")
      formElement.current.certifCode.selectedIndex = 0
      formElement.current.certifHours.value = ""
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/auth/coursedetails`)
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          ...names,
          token,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        success(json.text)
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <S.Grid>
      <S.Form
        method="post"
        action="/"
        onSubmit={e => handleSubmit(e)}
        ref={formElement}
      >
        <S.FormRow2>
          <Select
            name="categoryId"
            label="Category"
            reqd={true}
            autocomplete="off"
            data={[{ value: "", name: "--" }].concat(
              categories.map(cat => {
                return { value: cat.id, name: cat.name }
              })
            )}
            update={handleUpdate}
            errors={errors}
            value={data.categoryId}
          />

          <div className="empty" />
        </S.FormRow2>

        <S.FormRow1>
          <Input
            name="name"
            label="Course Name"
            reqd={true}
            autocomplete="off"
            update={handleUpdate}
            errors={errors}
            value={data.name}
          />
        </S.FormRow1>

        <S.FormRow2>
          <Input
            name="sponsor"
            label="Sponsor"
            reqd={true}
            autocomplete="off"
            update={handleUpdate}
            errors={errors}
            value={data.sponsor}
          />

          <Input
            name="expires"
            label="Expire Date"
            reqd={true}
            autocomplete="off"
            update={handleUpdate}
            errors={errors}
            value={data.expires}
          />
        </S.FormRow2>

        <S.FormRow3>
          <Input
            name="credit"
            label="Credit General"
            reqd={true}
            autocomplete="off"
            update={handleUpdate}
            errors={errors}
            value={data.credit}
          />

          <Input
            name="ethics"
            label="Ethics"
            reqd={false}
            autocomplete="off"
            update={handleUpdate}
            errors={errors}
            value={data.ethics}
          />

          <Input
            name="professionalism"
            label="Professionalism"
            reqd={false}
            autocomplete="off"
            update={handleUpdate}
            errors={errors}
            value={data.professionalism}
          />
        </S.FormRow3>

        <S.FormRow3>
          <Input
            name="technology"
            label="Technology"
            reqd={false}
            autocomplete="off"
            update={handleUpdate}
            errors={errors}
            value={data.technology}
          />

          <Input
            name="mental"
            label="Mental Illness"
            reqd={false}
            autocomplete="off"
            update={handleUpdate}
            errors={errors}
            value={data.mental}
          />

          <Input
            name="bias"
            label="Bias Elimination"
            reqd={false}
            autocomplete="off"
            update={handleUpdate}
            errors={errors}
            value={data.bias}
          />
        </S.FormRow3>

        <S.FormRow1>
          <S.Table className="certification">
            <thead>
              <tr>
                <th>Certification</th>
                <th>Hours</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {names.certif.length > 0 ? (
                names.certif.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="center">{item.credit}</td>
                      <td className="center">{item.hours}</td>
                      <td className="center trash">
                        <button
                          type="button"
                          className="naked"
                          onClick={() => handleRemove(index)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                        </button>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td
                    colSpan="3"
                    className="center working"
                    style={{ paddingTop: "15px", paddingBottom: "15px" }}
                  >
                    — no certications added —
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="3" style={{ borderBottom: "none" }}>
                  <S.FormRow2>
                    <Select
                      name="certifCode"
                      label="Credit Code"
                      reqd={true}
                      autocomplete="off"
                      data={[
                        {
                          id: "",
                          name: "--",
                        },
                      ].concat(certifCodes)}
                      update={handleUpdate}
                      errors={errors}
                    />

                    <Input
                      name="certifHours"
                      label="Credit Hours"
                      reqd={true}
                      autocomplete="off"
                      update={handleUpdate}
                      errors={errors}
                    />
                  </S.FormRow2>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="add">
                  <div>
                    <button type="button" onClick={e => handleClick(e)}>
                      <div>
                        <FontAwesomeIcon icon={faPlusCircle} />
                        <span>Add Certification</span>
                      </div>
                    </button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </S.Table>
        </S.FormRow1>

        <S.FormRow1>
          <S.Table2>
            <thead>
              <tr>
                <th>Format</th>
                <th>Member Price</th>
                <th>Non Member Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="center">CD</td>
                <td className="center input">
                  <input
                    type="text"
                    name="cdMember"
                    defaultValue={data.cdMember}
                    autoComplete="off"
                    onChange={e => handleUpdate("cdMember", e.target.value)}
                    className={isFieldError(errors, "cdMember") ? `error` : ``}
                  />
                </td>
                <td className="center input">
                  <input
                    type="text"
                    name="cdNonmember"
                    defaultValue={data.cdNonmember}
                    autoComplete="off"
                    onChange={e => handleUpdate("cdNonmember", e.target.value)}
                    className={
                      isFieldError(errors, "cdNonmember") ? `error` : ``
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="center">DVD</td>
                <td className="center input">
                  <input
                    type="text"
                    name="dvdMember"
                    defaultValue={data.dvdMember}
                    autoComplete="off"
                    onChange={e => handleUpdate("dvdMember", e.target.value)}
                    className={isFieldError(errors, "dvdMember") ? `error` : ``}
                  />
                </td>
                <td className="center input">
                  <input
                    type="text"
                    name="dvdNonmember"
                    defaultValue={data.dvdNonmember}
                    autoComplete="off"
                    onChange={e => handleUpdate("dvdNonmember", e.target.value)}
                    className={
                      isFieldError(errors, "dvdNonmember") ? `error` : ``
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="center">MP3</td>
                <td className="center input">
                  <input
                    type="text"
                    name="mp3Member"
                    defaultValue={data.mp3Member}
                    autoComplete="off"
                    onChange={e => handleUpdate("mp3Member", e.target.value)}
                    className={isFieldError(errors, "mp3Member") ? `error` : ``}
                  />
                </td>
                <td className="center input">
                  <input
                    type="text"
                    name="mp3Nonmember"
                    defaultValue={data.mp3Nonmember}
                    autoComplete="off"
                    onChange={e => handleUpdate("mp3Nonmember", e.target.value)}
                    className={
                      isFieldError(errors, "mp3Nonmember") ? `error` : ``
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="center">MP4</td>
                <td className="center input">
                  <input
                    type="text"
                    name="mp4Member"
                    defaultValue={data.mp4Member}
                    autoComplete="off"
                    onChange={e => handleUpdate("mp4Member", e.target.value)}
                    className={isFieldError(errors, "mp4Member") ? `error` : ``}
                  />
                </td>
                <td className="center input">
                  <input
                    type="text"
                    name="mp4Nonmember"
                    defaultValue={data.mp4Nonmember}
                    autoComplete="off"
                    onChange={e => handleUpdate("mp4Nonmember", e.target.value)}
                    className={
                      isFieldError(errors, "mp4Nonmember") ? `error` : ``
                    }
                  />
                </td>
              </tr>
            </tbody>
          </S.Table2>
        </S.FormRow1>

        <S.FormMsgSubmit>
          {msg.type && <Msg data={msg} />}
          {msg.type !== "working" && (
            <Submit name="Save Course Details" icon={faChevronCircleRight} />
          )}
        </S.FormMsgSubmit>
      </S.Form>
    </S.Grid>
  )
}

export default DetailsForm
