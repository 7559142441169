import React, { Fragment } from "react"
import { Router } from "@reach/router"
import Helmet from "../components/Helmet"
import PrivateRoute from "../components/PrivateRoute/admin" /* private route for admin area */
import Header from "../pagesAdmin/Header" /* header for admin area */
import Footer from "../pagesAdmin/Footer" /* footer for admin area */
import Home from "../pagesAdmin/Home"
import Category from "../pagesAdmin/Category"
import Add from "../pagesAdmin/Add"
import Manage from "../pagesAdmin/Manage"
import Stream from "../pagesAdmin/Stream"
import UploadCsv from "../pagesAdmin/UploadCsv"
import Signout from "../pagesAdmin/Signout"

const AdminPage = () => (
  <Fragment>
    <Helmet title="Course Administration" descr="" admin={true} />
    <Header />
    <Router>
      <PrivateRoute path="/admin/category/:categoryId" component={Category} />
      <PrivateRoute exact path="/admin/course/add" component={Add} />
      <PrivateRoute path="/admin/course/:courseId" component={Manage} />
      <PrivateRoute path="/admin/stream/:courseId" component={Stream} />
      <PrivateRoute exact path="/admin/csv" component={UploadCsv} />
      <PrivateRoute exact path="/admin/signout" component={Signout} />
      <PrivateRoute exact path="/admin" component={Home} />
    </Router>
    <Footer />
  </Fragment>
)
export default AdminPage
