import styled from "styled-components"

let S = {}
S.SectionContainer = styled.section`
  padding: 25px;
`

S.Grid = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 0.35fr;
  grid-gap: 15px;
  align-items: start;

  & > form:first-child {
    border: 1px solid var(--border);
    padding: 15px;
  }

  @media only screen and (max-width: 999px) {
    grid-template-columns: 1fr;
  }
`

export default S
