import styled from "styled-components"

export const Container = styled.div`
  &.invisible {
    visibility: hidden;
  }

  & label {
    display: block;
    cursor: pointer;
    font-size: 0.95rem;
    padding-bottom: 7.5px;
  }

  & label span.error {
    color: var(--error);
  }

  & input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  & label.input {
    width: 100%;
    border: 1px solid var(--border);
    border-radius: 2px;
    padding: 0;
    height: 38px;
    box-shadow: var(--boxshadow);
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    font-family: inherit;
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &.success {
      color: var(--success);
    }

    & > span {
      padding-left: 10px;
    }

    &:hover {
      border-color: var(--blue);
    }

    &.error {
      border-color: var(--error);
    }
  }
`
