import React, { useState, useRef, Fragment } from "react"
import { Link } from "gatsby"
import { storage } from "../../utils/firebase"
import { getValFromLS } from "../../utils"
import File from "../../components/File"
import Msg from "../../components/Msg"
import Submit from "../../components/Submit"
import {
  Breadcrumbs,
  Form,
  FormRow1,
  FormRow2,
  FormMsgSubmit,
} from "../../styles/styled"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronCircleRight,
} from "@fortawesome/pro-light-svg-icons"

S.Breadcrumbs = Breadcrumbs
S.Form = Form
S.FormRow1 = FormRow1
S.FormRow2 = FormRow2

S.FormMsgSubmit = FormMsgSubmit

const UploadCsv = () => {
  const [file, setFile] = useState(null)
  const [progress, setProgress] = useState(0)
  const [status, setStatus] = useState(5)
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})

  /*
    0 - error
    1 - complete (uploaded)
    10 - upload complete, encoding stream
    2 - initializing
    3 - initialized
    4 - uploading
    5 - idle
  */

  const formElement = useRef(null)

  const token = getValFromLS("clepbcbaAdminToken", true)

  const handleInitialize = e => {
    if (e.target.files[0]) {
      const fileData = e.target.files[0]
      setStatus(2)
      setMsg({
        type: "working",
        text: "",
      })
      const reader = new FileReader()

      reader.onload = loadEvt => {
        if (reader.readyState === 2) {
          if (fileData.size > 1999999999) {
            setStatus(0)
            setMsg({
              type: "error",
              text: `File upload size limited to around 1.75 GB.`,
            })
          } else if (fileData.type === "text/csv") {
            setFile(fileData)
            setStatus(3)
            setMsg({
              type: "notice",
              text: `File initialized (${fileData.type}${
                fileData.size ? `, ${fileData.size}` : ""
              }), click below to upload.`,
            })
          } else {
            setStatus(0)
            setMsg({
              type: "error",
              text: "Error initializing file, check the file and format (only CSV accepted).",
            })
          }
        }
      }

      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setStatus(4)
    setMsg({})

    const ref = storage.ref().child(`members.csv`)
    const uploadTask = ref.put(file)
    uploadTask.on(
      "state_changed",
      snapshot => {
        let step = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgress(step)
        setMsg({
          type: "working",
          text: `${snapshot.bytesTransferred} / ${snapshot.totalBytes}`,
        })

        /*
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING:
              console.log("Upload is running");
              break;
          }
        */
      },
      error => {
        console.log("error")
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadUrl => {
          handleResponse({ resp: 1, downloadUrl }, null)
        })
      }
    )
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        console.log(json.downloadUrl)

        setMsg({
          type: "success",
          text: "CSV file successfully uploaded.",
        })
      } else {
        setErrors("file")
        setMsg({
          type: "error",
          text: "An error has occurred uploading file.",
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <S.SectionContainer>
      <Fragment>
        <S.Breadcrumbs>
          <Link to="/admin">
            <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            <span style={{ paddingLeft: "7.5px" }}>All Categories</span>
          </Link>
          <span className="spacer">/</span>
          <span>Upload Members CSV</span>
        </S.Breadcrumbs>

        <S.Grid>
          <S.Form
            method="post"
            action="/"
            onSubmit={e => handleSubmit(e)}
            ref={formElement}
          >
            <S.FormRow2>
              <File
                name="file"
                label="Upload File"
                reqd={true}
                autocomplete="off"
                update={handleInitialize}
                errors={errors}
                selected={file ? true : false}
              />

              <div className="empty" />
            </S.FormRow2>

            {status === 4 && (
              <S.FormRow1>
                <S.UploadingContainer>
                  {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(
                    (item, index) => {
                      return (
                        <div
                          key={index}
                          className={item <= progress ? "complete" : ""}
                        />
                      )
                    }
                  )}
                </S.UploadingContainer>
              </S.FormRow1>
            )}

            <S.FormMsgSubmit>
              {msg.type && <Msg data={msg} />}
              {msg.type === "notice" && (
                <Submit name="Upload Members CSV" icon={faChevronCircleRight} />
              )}
            </S.FormMsgSubmit>
          </S.Form>
        </S.Grid>
      </Fragment>
    </S.SectionContainer>
  )
}

export default UploadCsv
