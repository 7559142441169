import React from "react"
import { Link } from "gatsby"
import logo from "../../img/pbcba-logo.png"
import S from "./styled"

const Header = () => {
  return (
    <S.SectionContainer>
      <header>
        <Link to="/admin">
          <img src={logo} alt="PBCBA Logo" />
          <h1>CLE Course Admin</h1>
        </Link>

        <div>
          <Link to="/admin/csv" className="button">
            Upload CSV
          </Link>

          <Link to="/admin/signout" className="button">
            Sign Out
          </Link>
        </div>
      </header>
    </S.SectionContainer>
  )
}

export default Header
