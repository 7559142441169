import React, { useState, useEffect, Fragment } from "react"
import { Link } from "gatsby"
import useFetch from "../../hooks/useFetch"
import { API_URL, getValFromLS } from "../../utils"
import DetailsForm from "./DetailsForm"
import AssetsForm from "./AssetsForm"
import DeleteForm from "./DeleteForm"
import Alert from "../../components/Alert"
import { Breadcrumbs } from "../../styles/styled"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons"

S.Breadcrumbs = Breadcrumbs

const Manage = ({ courseId }) => {
  const [url, setUrl] = useState(null)
  const [details, setDetails] = useState({})
  const [selected, setSelected] = useState("")
  const [alert, setAlert] = useState({
    type: "notice",
    text: "Select from options above to manage course details or media assets.",
  })

  const { loading, data } = useFetch(url)

  const token = getValFromLS("clepbcbaAdminToken", true)

  useEffect(() => {
    const newUrl = new URL(`${API_URL}/auth/courseInit`)
    const params = {
      courseId,
      token,
    }
    newUrl.search = new URLSearchParams(params)
    setUrl(newUrl)
  }, [])

  useEffect(() => {
    if (data && data.resp === 1) {
      setDetails({
        categoryId: data.categoryId,
        categoryName: data.categoryName,
        courseName: data.name,
      })
    }
  }, [data])

  const handleSuccess = text => {
    setSelected("")
    setAlert({
      type: "success",
      text,
    })
    const newUrl = new URL(`${API_URL}/auth/courseInit`)
    const params = {
      courseId,
      token,
      q: "123",
    }
    newUrl.search = new URLSearchParams(params)
    setUrl(newUrl)
  }

  return (
    <S.SectionContainer>
      {loading ? (
        <div>Loading...</div>
      ) : data.resp === 0 ? (
        <div>Error loading data.</div>
      ) : data.resp === 999 ? (
        <div>
          Authentication error. <Link to="/admin">Refresh Credentials</Link>
        </div>
      ) : (
        <Fragment>
          <S.Breadcrumbs>
            <Link to="/admin">
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
              <span style={{ paddingLeft: "7.5px" }}>All Categories</span>
            </Link>
            <span className="spacer">/</span>
            <Link to={`/admin/category/${details.categoryId}`}>
              <span>{details.categoryName}</span>
            </Link>
            <span className="spacer">/</span>
            <span>{details.courseName}</span>
          </S.Breadcrumbs>

          <S.ButtonContainer>
            <button
              type="button"
              onClick={() => setSelected("details")}
              className={selected === "details" ? "selected" : ""}
            >
              Manage Course Details
            </button>
            <button
              type="button"
              onClick={() => setSelected("assets")}
              className={selected === "assets" ? "selected" : ""}
            >
              Upload Media Assets
            </button>
            <button
              type="button"
              onClick={() => setSelected("delete")}
              className={selected === "delete" ? "selected" : ""}
            >
              Delete Course
            </button>
          </S.ButtonContainer>

          {!selected && alert.type && (
            <S.AlertContainer>
              <Alert data={alert} />
            </S.AlertContainer>
          )}

          {selected === "details" && (
            <DetailsForm
              data={{
                courseId,
                categoryId: data.categoryId,
                name: data.name,
                sponsor: data.sponsor,
                expires: data.expires,
                credit: data.credit,
                ethics: data.ethics,
                professionalism: data.professionalism,
                technology: data.technology,
                mental: data.mental,
                bias: data.bias,
                certif: data.certif,
                cdMember: data.cd.member,
                cdNonmember: data.cd.nonmember,
                dvdMember: data.dvd.member,
                dvdNonmember: data.dvd.nonmember,
                mp3Member: data.mp3.member,
                mp3Nonmember: data.mp3.nonmember,
                mp4Member: data.mp4.member,
                mp4Nonmember: data.mp4.nonmember,
              }}
              token={token}
              categories={data.categories}
              certifCodes={data.certifCodes}
              success={handleSuccess}
            />
          )}

          {selected === "assets" && (
            <AssetsForm
              data={{
                courseId,
                name: data.name,
                assets: data.assets,
              }}
              token={token}
              success={handleSuccess}
            />
          )}

          {selected === "delete" && (
            <DeleteForm
              data={{
                courseId,
                name: data.name,
                assets: data.assets,
              }}
              token={token}
            />
          )}
        </Fragment>
      )}
    </S.SectionContainer>
  )
}

export default Manage
