import styled from "styled-components"

let S = {}
S.SectionContainer = styled.section`
  padding: 15px;
  border-bottom: 1px solid var(--border);
  box-shadow: var(--boxshadow);
  background-color: var(--bg);

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 649px) {
      flex-direction: column;
    }

    & a {
      display: block;
    }

    & > a:first-child {
      width: 100%;
      display: flex;
      align-items: center;

      @media only screen and (max-width: 649px) {
        margin-bottom: 5px;
      }

      & > img {
        width: 60px;
      }

      & > h1 {
        margin-left: 10px;
      }
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    & > div {
      @media only screen and (max-width: 649px) {
        margin-bottom: 5px;
      }
    }

    & > div > a:last-child {
      margin-left: 15px;
    }
  }
`

export default S
