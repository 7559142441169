import React, { useState, useEffect, Fragment } from "react"
import { Link } from "gatsby"
import useFetch from "../../hooks/useFetch"
import { API_URL } from "../../utils"

const Courses = ({ categoryId, categoryName, token }) => {
  const [url, setUrl] = useState(null)
  const [courses, setCourses] = useState([])

  const { loading, data } = useFetch(url)

  useEffect(() => {
    const newUrl = new URL(`${API_URL}/auth/coursesInit`)
    const params = {
      categoryId,
      token,
    }
    newUrl.search = new URLSearchParams(params)
    setUrl(newUrl)
  }, [])

  useEffect(() => {
    if (data && data.courses.length > 0) {
      setCourses(data.courses)
    }
  }, [data])

  return (
    <Fragment>
      {loading ? (
        <div style={{ padding: "15px" }}>Loading...</div>
      ) : (
        <ul>
          <li key="0">
            <Link to="/admin/course/add" state={{ categoryId, categoryName }}>
              Add a New Course
            </Link>
          </li>
          {courses.map(item => {
            return (
              <li key={item.id}>
                <Link
                  to={`/admin/course/${item.id}`}
                  className={item.expired ? "expired" : ""}
                >
                  {item.name}
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </Fragment>
  )
}

export default Courses
