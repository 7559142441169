import React, { useState, useEffect, Fragment } from "react"
import { Link } from "gatsby"
import useFetch from "../../hooks/useFetch"
import { API_URL, getValFromLS } from "../../utils"
import Alert from "../../components/Alert"
import { Breadcrumbs } from "../../styles/styled"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons"

S.Breadcrumbs = Breadcrumbs

const Stream = ({ location, courseId }) => {
  const [url, setUrl] = useState(null)

  const { loading, data } = useFetch(url)

  const token = getValFromLS("clepbcbaAdminToken", true)

  const courseName = location && location.state ? location.state.courseName : ""

  useEffect(() => {
    const newUrl = new URL(`${API_URL}/auth/streamInit`)
    const params = {
      courseId,
      token,
    }
    newUrl.search = new URLSearchParams(params)
    setUrl(newUrl)
  }, [])

  return (
    <S.SectionContainer>
      {loading ? (
        <div>Loading...</div>
      ) : data.resp === 0 ? (
        <div>Error loading data.</div>
      ) : data.resp === 2 ? (
        <Alert data={{ type: "notice", text: data.text }} />
      ) : data.resp === 999 ? (
        <div>
          Authenticaton error. Please <Link to="/admin">renew</Link> your sign
          in credentials.
        </div>
      ) : (
        <Fragment>
          <S.Breadcrumbs>
            <Link to="/admin">
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
              <span style={{ paddingLeft: "7.5px" }}>All Categories</span>
            </Link>
            <span className="spacer">/</span>
            <Link to={`/admin/course/${courseId}`}>
              <span>{courseName}</span>
            </Link>
            <span className="spacer">/</span>
            <span>View Stream</span>
          </S.Breadcrumbs>

          <S.VideoContainer>
            <iframe
              src={`https://iframe.videodelivery.net/${data.cfstreamToken}`}
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowFullScreen="true"
              title="Stream Video"
            ></iframe>
          </S.VideoContainer>
        </Fragment>
      )}
    </S.SectionContainer>
  )
}

export default Stream
