import React from "react"
import { Link } from "gatsby"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf, faCheck } from "@fortawesome/pro-light-svg-icons"

const ResultsTable = ({ courses }) => {
  return (
    <S.Table>
      <thead>
        <tr>
          <th className="left">Name</th>
          <th>Expires</th>
          <th className="hide-on-mobile">Description</th>
          <th className="hide-on-mobile">Documents</th>
          <th className="hide-on-mobile">MP3</th>
          <th className="hide-on-mobile">MP4</th>
        </tr>
      </thead>
      <tbody>
        {courses.map(item => {
          return (
            <tr key={item.id}>
              <td>
                <Link to={`/admin/course/${item.id}`}>{item.name}</Link>
              </td>
              <td className={item.expired ? "center error" : "center"}>
                {item.expires}
              </td>
              <td className="center hide-on-mobile">
                {item.description && (
                  <FontAwesomeIcon icon={faFilePdf} size="lg" />
                )}
              </td>
              <td className="center hide-on-mobile">{item.documents}</td>
              <td className="center hide-on-mobile">
                {item.mp3 && <FontAwesomeIcon icon={faCheck} size="lg" />}
              </td>
              <td className="center hide-on-mobile">
                {item.mp4 && <FontAwesomeIcon icon={faCheck} size="lg" />}
              </td>
            </tr>
          )
        })}
      </tbody>
    </S.Table>
  )
}

export default ResultsTable
