import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.15rem;
  text-align: center;

  @media print {
    display: none;
  }

  &.working {
    color: var(--working);
  }

  &.success {
    color: var(--success);
  }

  &.notice {
    color: var(--notice);
  }

  &.error {
    color: var(--error);
  }

  & > span {
    padding-top: 7.5px;
  }
`
