import styled from "styled-components"

let S = {}
S.SectionContainer = styled.section`
  padding: 25px;
`

S.VideoContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  position: relative;
  padding-top: 56.25%;

  & > iframe {
    border: none;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
`

export default S
