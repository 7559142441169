import styled from "styled-components"

export const Container = styled.div`
  width: 100%;

  & button {
    border: none;
    text-align: left;
    color: #000;
    padding: 0;
    padding-top: 5px;

    &:hover,
    &:focus,
    &:active {
      color: #000;
    }

    & > div {
      display: flex;
      align-items: center;

      & > span {
        padding-left: 7.5px;
      }
    }
  }
`
