import React, { useState, useEffect, Fragment } from "react"
import { Link } from "gatsby"
import useFetch from "../../hooks/useFetch"
import { API_URL, getValFromLS } from "../../utils"
import ResultsTable from "../../components/ResultsTable"
import { Breadcrumbs } from "../../styles/styled"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons"

S.Breadcrumbs = Breadcrumbs

const Category = ({ categoryId }) => {
  const [url, setUrl] = useState(null)

  const { loading, data } = useFetch(url)

  const token = getValFromLS("clepbcbaAdminToken", true)

  useEffect(() => {
    const newUrl = new URL(`${API_URL}/auth/coursesInit`)
    const params = {
      categoryId,
      token,
    }
    newUrl.search = new URLSearchParams(params)
    setUrl(newUrl)
  }, [])

  return (
    <S.SectionContainer>
      {loading ? (
        <div>Loading...</div>
      ) : data.resp === 0 ? (
        <div>Error loading data.</div>
      ) : data.resp === 999 ? (
        <div>
          Authenticaton error. Please <Link to="/admin">renew</Link> your sign
          in credentials.
        </div>
      ) : (
        <Fragment>
          <S.Breadcrumbs>
            <Link to="/admin">
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
              <span style={{ paddingLeft: "7.5px" }}>All Categories</span>
            </Link>
            <span className="spacer">/</span>
            <span>{`${data.categoryName} Courses`}</span>
          </S.Breadcrumbs>

          <ResultsTable courses={data.courses} />
        </Fragment>
      )}
    </S.SectionContainer>
  )
}

export default Category
