import React, { useState, useRef, Fragment } from "react"
import { navigate } from "gatsby"
import { storage } from "../../utils/firebase"
import { API_URL } from "../../utils"
import Checkbox from "../../components/Checkbox"
import Msg from "../../components/Msg"
import Submit from "../../components/Submit"
import { Form, FormRow1, FormRow2, FormMsgSubmit } from "../../styles/styled"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronCircleRight,
  faFilePdf,
  faFileAudio,
  faFileVideo,
  faTvAlt,
} from "@fortawesome/pro-light-svg-icons"

S.Form = Form
S.FormRow1 = FormRow1
S.FormRow2 = FormRow2
S.FormMsgSubmit = FormMsgSubmit

const DeleteForm = ({ data, token }) => {
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})

  const formElement = useRef(null)

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    if (!names.confirm) {
      setMsg({
        type: "error",
        text: "Please confirm course deletion to continue.",
      })

      return false
    }

    const listRef = storage.ref().child(`courses/${data.courseId}`)

    listRef
      .listAll()
      .then(res => {
        res.items.forEach(async (itemRef, index) => {
          const result = await itemRef.delete()
        })
        handleDelete()
      })
      .catch(error => {
        setMsg({
          type: "error",
          text: `An error occurred deleting course ${error}.`,
        })
      })
  }

  const handleDelete = async () => {
    const url = new URL(`${API_URL}/auth/coursedelete`)
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          ...names,
          courseId: data.courseId,
          token,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        navigate("/admin")
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <S.Grid>
      <S.Form
        method="post"
        action="/"
        onSubmit={e => handleSubmit(e)}
        ref={formElement}
      >
        <S.FormRow1>
          <S.Table4>
            <thead>
              <tr>
                <th className="left">{data.name}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>
                    Check below and submit to delete course and all accompanying
                    asset files.
                  </p>
                  <p>
                    <small style={{ color: "var(--error)" }}>
                      Please note that course data and assets can not be
                      recovered once deleted.
                    </small>
                  </p>
                </td>
              </tr>

              {data.assets.map((item, index) => {
                return (
                  item.status && (
                    <Fragment key={index}>
                      <tr>
                        <td>
                          <div>
                            <span>
                              {item.icon && (
                                <FontAwesomeIcon
                                  icon={
                                    item.icon === "audio"
                                      ? faFileAudio
                                      : item.icon === "video"
                                      ? faFileVideo
                                      : faFilePdf
                                  }
                                  size="lg"
                                />
                              )}
                            </span>
                            <span>{item.name}</span>
                          </div>
                        </td>
                      </tr>

                      {item.stream && (
                        <tr>
                          <td>
                            <div>
                              <span>
                                {item.icon && (
                                  <FontAwesomeIcon icon={faTvAlt} size="md" />
                                )}
                              </span>
                              <span>Stream</span>
                            </div>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )
                )
              })}
            </tbody>
          </S.Table4>
        </S.FormRow1>

        <S.FormRow1 style={{ marginTop: "5px", marginBottom: "7.5px" }}>
          <Checkbox
            name="confirm"
            label="Check to confirm permanent course deletion"
            reqd={false}
            update={handleUpdate}
            errors={errors}
          />
        </S.FormRow1>

        <S.FormMsgSubmit>
          {msg.type && <Msg data={msg} />}
          {msg.type !== "working" && (
            <Submit
              name="Delete Course Details & Assets"
              icon={faChevronCircleRight}
            />
          )}
        </S.FormMsgSubmit>
      </S.Form>
    </S.Grid>
  )
}

export default DeleteForm
