import React from "react"
import { navigate } from "gatsby"
import { getValFromLS } from "../../utils"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (
    !getValFromLS("clepbcbaAdmin", true) &&
    typeof window !== `undefined` &&
    location.pathname !== `/signin`
  ) {
    navigate("/signin")
    return null
  }
  return <Component location={location} {...rest} />
}
export default PrivateRoute
