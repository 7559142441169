import styled from "styled-components"

let S = {}
S.SectionContainer = styled.section`
  padding: 25px;

  & > h4 {
    margin-bottom: 15px;
    font-size: 1.1rem;
  }

  & > form {
    margin-bottom: 35px;

    & button {
      width: 150px;
      height: 38px;
      color: var(--blue);

      @media only screen and (max-width: 649px) {
        width: 100%;
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;

        & > span {
          font-size: 0.9rem;
          font-weight: 600;
          padding-right: 7.5px;
          text-transform: uppercase;
        }

        & > svg {
          font-size: 1.1rem;
        }
      }
    }

    & button:hover {
      color: var(--orange);
    }
  }

  & > table {
    margin-bottom: 35px;
  }
`

S.Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  align-items: start;

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  & > div {
    border: 1px solid var(--border);

    & > button {
      border: none;
      width: 100%;
      padding: 7.5px 0 6.5px 0;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1rem;
      letter-spacing: 0.75px;
      transition: none;

      &:hover {
        background-color: var(--bg);
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
      }

      & > div > *:first-child {
        color: transparent;
      }
    }

    & > ul {
      border-top: 1px solid var(--border);
      margin-top: 0;
      padding-top: 15px;
      margin-left: 0;
      padding-left: 32.5px;
    }

    & > ul.hidden {
      display: none;
    }

    & > ul > li {
      font-size: 0.9rem;
      margin-bottom: 5px;
      padding-right: 15px;
    }

    & > ul > li:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    & > ul > li > a.expired {
      text-decoration: line-through;
      text-decoration-style: solid;
      text-decoration-color: var(--error);
    }
  }

  & > div.selected {
    border: 1px solid var(--blue);

    & > button {
      background-color: var(--blue);
      color: #fff;
    }
  }
`

export default S
