import styled from "styled-components"
import { Table as BaseTable } from "../../styles/styled"

let S = {}
S.SectionContainer = styled.section`
  padding: 25px;
`

S.ButtonContainer = styled.div`
  margin-top: 25px;
  padding-bottom: 16px;
  display: grid;
  grid-template-columns: 33% 34% 33%;
  position: relative;

  @media only screen and (max-width: 649px) {
    grid-template-columns: 100%;
    padding-bottom: 98px;
  }

  & > button {
    position: absolute;
    top: 0;
    left: 0;
    width: 33%;
    vertical-align: middle;
    padding: 7.5px 7.5px;
    font-family: inherit;
    font-size: 15.75px;
    line-height: 25px;
    font-weight: 600;
    outline: none;
    border: none;
    border: 1px solid var(--blue);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    color: var(--blue);
    text-transform: uppercase;
  }

  & > button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    @media only screen and (max-width: 649px) {
      width: 100%;
      border-top-right-radius: 2px;
    }
  }

  & > button:nth-child(2) {
    left: calc(33% - 1px);
    width: 34%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    @media only screen and (max-width: 649px) {
      width: 100%;
      left: 0;
      top: 41px;
    }
  }

  & > button:last-child {
    left: calc(67% - 1px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;

    @media only screen and (max-width: 649px) {
      width: 100%;
      left: 0;
      top: 82px;
      border-left: 1px solid var(--blue);
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  & > button:hover {
    background-color: var(--bg);
  }

  & > button.selected,
  & > button.selected:hover {
    background-color: var(--blue);
    border-color: var(--blue);
    color: #fff;
  }
`

S.AlertContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 50px;
`

S.Grid = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  align-items: start;

  & > form:first-child {
    border: 1px solid var(--border);
    padding: 15px;
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

S.Table = styled(BaseTable)`
  margin-top: 25px;

  &.certification th {
    width: 22%;

    @media only screen and (max-width: 649px) {
      width: 24%;
    }
  }

  &.certification th:first-child {
    width: 64%;

    @media only screen and (max-width: 649px) {
      width: 52%;
    }
  }

  & tbody td {
    padding-top: 5px;
    padding-bottom: 7.5px;
    vertical-align: middle;
  }

  & tfoot td {
    padding: 15px 25px 5px 25px;
  }

  & td.trash svg {
    color: var(--error);
    font-size: 1.05rem;
  }

  & td.add {
    border-top: none;
    text-align: center;
    padding-bottom: 20px;

    & button {
      border-radius: 2px;
      font-size: 0.95rem;
    }

    & button:hover {
      background-color: var(--bg);
    }

    & button > div {
      display: flex;
      align-items: center;

      & > span {
        padding-left: 5px;
      }
    }
  }
`

S.Table2 = styled(BaseTable)`
  margin-top: 25px;

  & tr:first-child th:first-child {
    width: 12.5%;
  }

  & td.input {
    padding: 0;
    padding: 7.5px;

    & input {
      text-align: center;
      width: 50%;
      border: none;
      border-radius: 0;
      border: 1px solid transparent;
      padding: 2.5px 0 3.5px 0;
      font-family: inherit;
      font-size: 16px;
      line-height: inherit;
      font-weight: inherit;
      outline: none;

      @media only screen and (max-width: 649px) {
        width: 100%;
      }
    }

    & input:focus {
      border-color: var(--border);
    }

    & input.error {
      border-color: var(--error);
    }
  }
`

S.Table3 = styled(BaseTable)`
  margin-top: 0;

  & thead tr:first-child th:first-child {
    width: 12.5%;
  }

  & tbody tr td:nth-child(3),
  & tbody tr td:last-child {
    width: 15%;
    font-size: 0.7rem;
  }

  & tbody td {
    padding: 10px 7.5px 7.5px 7.5px;
  }

  & tbody td.success {
    color: var(--success);
  }

  & tbody td.error {
    color: var(--error);
  }

  & tbody td > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 2.5px;
  }
`

S.Table4 = styled(BaseTable)`
  margin-top: 0;

  & tbody td {
    padding: 10px 7.5px 7.5px 7.5px;
  }

  & tbody td.success {
    color: var(--success);
  }

  & tbody td.error {
    color: var(--error);
  }

  & tbody td > div {
    display: flex;
    align-items: center;
    padding-left: 0;

    & > span:first-child {
      display: block;
      text-align: center;
      width: 37.5px;
    }
  }
`

S.UploadingContainer = styled.div`
  padding-top: 25px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25px, 25px));
  grid-gap: 15px;
  justify-content: center;

  & > div {
    width: 25px;
    height: 25px;
    border: 1px solid var(--working);
  }

  & > div.complete {
    background-color: var(--working);
  }
`

export default S
