import React, { useState } from "react"
import { isFieldError } from "../../utils/"
import { Container } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquare, faCheckSquare } from "@fortawesome/pro-light-svg-icons"

const Checkbox = ({ name, label, reqd, update, errors }) => {
  const [checked, setChecked] = useState(false)

  const handleClick = name => {
    setChecked(!checked)
    update(name, !checked)
  }

  return (
    <Container className={isFieldError(errors, name) ? `error` : ``}>
      <button type="button" onClick={e => handleClick(name)}>
        <div>
          <FontAwesomeIcon icon={checked ? faCheckSquare : faSquare} />
          <span>
            {`${label}`} {reqd ? <span className="error">*</span> : null}
          </span>
        </div>
      </button>
    </Container>
  )
}
export default Checkbox
