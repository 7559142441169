import React, { useState, useEffect, useRef, Fragment } from "react"
import { navigate } from "gatsby"
import useFetch from "../../hooks/useFetch"
import { firebase } from "../../utils/firebase"
import { API_URL, setValToLS } from "../../utils"
import Courses from "./Courses"
import ResultsTable from "../../components/ResultsTable"
import Input from "../../components/Input"
import Msg from "../../components/Msg"
import { Form, FormRow2, FormMsgSubmit } from "../../styles/styled"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronDown,
  faChevronCircleRight,
} from "@fortawesome/pro-light-svg-icons"

S.Form = Form
S.FormRow2 = FormRow2
S.FormMsgSubmit = FormMsgSubmit

const Home = () => {
  const [token, setToken] = useState(null)
  const [url, setUrl] = useState(null)
  const [categories, setCategories] = useState([])
  const [selected, setSelected] = useState("")
  const [results, setResults] = useState([])
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState(5)

  /*
    0 - error
    1 - searched, found results
    2 - searched, no results
    4 - searching
    5 - idle
  */

  const { loading, data } = useFetch(url)

  const formElement = useRef(null)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user
          .getIdToken(true)
          .then(idToken => {
            setToken(idToken)
            setValToLS("clepbcbaAdminToken", idToken, true)
          })
          .catch(function (error) {
            navigate("/signin")
          })
      } else {
        navigate("/signin")
      }
    })
  }, [])

  useEffect(() => {
    if (!token) return

    /* not using protected resource here since just need categories */
    const newUrl = new URL(`${API_URL}/homeInit`)
    const params = {
      crit: "areaoflaw",
    }
    newUrl.search = new URLSearchParams(params)
    setUrl(newUrl)
  }, [token])

  useEffect(() => {
    if (data && data.resp === 1) {
      setCategories(data.categories)
    }
  }, [data])

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleClick = categoryId => {
    if (selected === categoryId) {
      setSelected("")
    } else {
      setSelected(categoryId)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setStatus(4)

    const newUrl = new URL(`${API_URL}/auth/search`)
    const params = {
      q: names.q || "",
      token,
    }
    newUrl.search = new URLSearchParams(params)

    try {
      const response = await fetch(newUrl, {
        method: "GET",
        cache: "no-store",
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        setResults(json.courses)
        setErrors(json.courses.length > 0 ? [] : ["q"])
        setStatus(json.courses.length > 0 ? 1 : 2)
      } else {
        setErrors(["q"])
        setStatus(0)
      }
    } else {
      setStatus(0)
    }
  }

  return (
    <S.SectionContainer>
      {loading ? (
        <div>Loading...</div>
      ) : data.resp === 0 ? (
        <div>Error loading data.</div>
      ) : data.resp === 999 ? (
        <div>Authenticaton error.</div>
      ) : (
        <Fragment>
          <h4>Search by Keyword in Course Name</h4>

          <S.Form
            method="post"
            action="/"
            onSubmit={e => handleSubmit(e)}
            ref={formElement}
            style={{ padding: "0" }}
          >
            <S.FormRow2>
              <Input
                name="q"
                label=""
                reqd={true}
                autocomplete="off"
                update={handleUpdate}
                errors={errors}
              />

              {status !== 4 && (
                <button type="submit">
                  <div>
                    <span>Search</span>
                    <FontAwesomeIcon icon={faChevronCircleRight} size="lg" />
                  </div>
                </button>
              )}
            </S.FormRow2>
          </S.Form>

          {(status === 0 || status === 2) && (
            <Msg
              data={{
                type: "error",
                text:
                  status === 2
                    ? "No course names matched that search."
                    : "Please enter a keyword to search courses.",
              }}
            />
          )}
          {status === 1 && <ResultsTable courses={results} />}

          <h4 style={{ marginTop: "15px" }}>Browse Courses by Category</h4>

          <S.Grid>
            {categories.map(item => {
              return (
                <div
                  key={item.id}
                  className={item.id === selected ? "selected" : ""}
                >
                  <button type="button" onClick={() => handleClick(item.id)}>
                    <div>
                      <FontAwesomeIcon icon={faChevronRight} size="lg" />
                      {item.name}
                      <FontAwesomeIcon
                        icon={
                          item.id === selected ? faChevronDown : faChevronRight
                        }
                        size="1x"
                      />
                    </div>
                  </button>
                  {item.id === selected && (
                    <Courses
                      categoryId={item.id}
                      categoryName={item.name}
                      token={token}
                    />
                  )}
                </div>
              )
            })}
          </S.Grid>
        </Fragment>
      )}
    </S.SectionContainer>
  )
}

export default Home
