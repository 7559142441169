import React from "react"
import { isFieldError } from "../../utils/"
import { Container, SelectContainer } from "./styled"

const Select = ({
  name,
  label,
  reqd,
  autocomplete = name,
  data,
  update,
  errors,
  visible = true,
  value = "",
}) => {
  return (
    <Container className={visible ? `` : `invisible`}>
      <label htmlFor={name}>
        {`${label}`} {reqd ? <span className="error">*</span> : null}
      </label>
      <SelectContainer className={isFieldError(errors, name) ? `error` : ``}>
        <select
          name={name}
          id={name}
          autoComplete={autocomplete}
          onChange={e => update(name, e.target.value)}
          defaultValue={value}
        >
          <option value="">--</option>
          {data.map(obj => {
            return (
              <optgroup label={obj.label} key={obj.label}>
                {obj.data.map((obj2, index) => {
                  return (
                    <option value={obj2.value} key={index}>
                      {obj2.name}
                    </option>
                  )
                })}
              </optgroup>
            )
          })}
        </select>
      </SelectContainer>
    </Container>
  )
}
export default Select
