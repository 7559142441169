import styled from "styled-components"
import { Table as BaseTable } from "../../styles/styled"

let S = {}
S.Table = styled(BaseTable)`
  margin-top: 25px;

  & tbody td {
    padding: 10px 7.5px 7.5px 7.5px;
  }

  & thead th:nth-child(5),
  & thead th:nth-child(6) {
    width: 6%;
  }

  & tbody td.success {
    color: var(--success);
  }

  & tbody td.error {
    color: var(--error);
  }

  & td.hide-on-mobile,
  & th.hide-on-mobile {
    @media only screen and (max-width: 649px) {
      display: none;
    }
  }
`
export default S
