import React from "react"
import { isFieldError } from "../../utils/"
import { Container } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUpload, faCheckCircle } from "@fortawesome/pro-light-svg-icons"

const File = ({
  name,
  label,
  reqd,
  autocomplete = name,
  update,
  errors,
  visible = true,
  selected = false,
}) => {
  return (
    <Container className={visible ? `` : `invisible`}>
      <label htmlFor={name}>
        {`${label}`} {reqd ? <span className="error">*</span> : null}
      </label>
      <input
        type="file"
        name={name}
        id={name}
        autoComplete={autocomplete}
        onChange={e => update(e)}
        className={isFieldError(errors, name) ? `error` : ``}
      />
      {selected ? (
        <label htmlFor={name} className="input success">
          <FontAwesomeIcon icon={faCheckCircle} size="lg" />
          <span>File Selected</span>
        </label>
      ) : (
        <label htmlFor={name} className="input">
          <FontAwesomeIcon icon={faUpload} size="lg" />
          <span>Select a File </span>
        </label>
      )}
    </Container>
  )
}
export default File
