import styled from "styled-components"

let S = {}
S.SectionContainer = styled.section`
  padding: 25px;
`

S.Grid = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 0.35fr;
  grid-gap: 15px;
  align-items: start;

  & > form:first-child {
    border: 1px solid var(--border);
    padding: 15px;
  }

  @media only screen and (max-width: 999px) {
    grid-template-columns: 1fr;
  }
`

S.UploadingContainer = styled.div`
  padding-top: 25px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25px, 25px));
  grid-gap: 15px;
  justify-content: center;

  & > div {
    width: 25px;
    height: 25px;
    border: 1px solid var(--working);
  }

  & > div.complete {
    background-color: var(--working);
  }
`

export default S
