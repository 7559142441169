import React from "react"
import S from "./styled"

const Footer = () => {
  return (
    <S.SectionContainer>
      <div>
        <p className="subhdg">
          Please contact Q New Media at{" "}
          <a href="mailto:kylan@qnewmedia.com">kylan@qnewmedia.com</a> with any
          questions or technical issues.
        </p>

        <small>
          <span>©</span>
          <span>
            2021-22, Palm Beach County Bar Association. All Rights Reserved.
          </span>
        </small>
      </div>
    </S.SectionContainer>
  )
}

export default Footer
