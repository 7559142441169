import React from "react"
import { isFieldError } from "../../utils/"
import { Container } from "./styled"

const Input = ({
  type = "text",
  name,
  label,
  reqd,
  autocomplete = name,
  update,
  errors,
  visible = true,
  value = "",
}) => {
  return (
    <Container className={visible ? `` : `invisible`}>
      <label htmlFor={name} className={!label ? "hidden" : ""}>
        {`${label}`} {reqd ? <span className="error">*</span> : null}
      </label>
      <input
        type={type === "text-secure" ? "text" : type}
        name={type === "text-secure" ? null : name}
        data-encrypted-name={type === "text-secure" ? name : null}
        id={name}
        autoComplete={autocomplete}
        onChange={e => update(name, e.target.value)}
        className={isFieldError(errors, name) ? `error` : ``}
        defaultValue={value}
      />
    </Container>
  )
}
export default Input
