import React, { useState, useEffect, useRef, Fragment } from "react"
import { Link, navigate } from "gatsby"
import useFetch from "../../hooks/useFetch"
import { API_URL, getValFromLS } from "../../utils"
import Input from "../../components/Input"
import Select from "../../components/Select"
import Msg from "../../components/Msg"
import Submit from "../../components/Submit"
import {
  Breadcrumbs,
  Form,
  FormRow1,
  FormRow2,
  FormRow3,
  FormMsgSubmit,
} from "../../styles/styled"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronCircleRight,
} from "@fortawesome/pro-light-svg-icons"

S.Breadcrumbs = Breadcrumbs
S.Form = Form
S.FormRow1 = FormRow1
S.FormRow2 = FormRow2
S.FormRow3 = FormRow3
S.FormMsgSubmit = FormMsgSubmit

const Add = ({ location }) => {
  const [url, setUrl] = useState(null)
  const [names, setNames] = useState({
    categoryId: location && location.state ? location.state.categoryId : "",
  })
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})

  const { loading, data } = useFetch(url)

  const formElement = useRef(null)

  const token = getValFromLS("clepbcbaAdminToken", true)

  const categoryId = location && location.state ? location.state.categoryId : ""
  const categoryName =
    location && location.state ? location.state.categoryName : ""

  useEffect(() => {
    /* not using protected resource here since just need categories */
    const newUrl = new URL(`${API_URL}/homeInit`)
    const params = {
      crit: "areaoflaw",
    }
    newUrl.search = new URLSearchParams(params)
    setUrl(newUrl)
  }, [])

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/auth/courseadd`)
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          ...names,
          token,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        navigate(`/admin/course/${json.courseId}`)
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <S.SectionContainer>
      {loading ? (
        <div>Loading...</div>
      ) : data.resp === 0 ? (
        <div>Error loading data.</div>
      ) : data.resp === 999 ? (
        <div>Authenticaton error.</div>
      ) : (
        <Fragment>
          {categoryId ? (
            <S.Breadcrumbs>
              <Link to="/admin">
                <FontAwesomeIcon icon={faChevronLeft} size="lg" />
                <span style={{ paddingLeft: "7.5px" }}>All Categories</span>
              </Link>
              <span className="spacer">/</span>
              <Link to={`/admin/category/${categoryId}`}>
                <span>{categoryName}</span>
              </Link>
              <span className="spacer">/</span>
              <span>Add Course</span>
            </S.Breadcrumbs>
          ) : (
            <S.Breadcrumbs>
              <Link to="/admin">
                <FontAwesomeIcon icon={faChevronLeft} size="lg" />
                <span style={{ paddingLeft: "7.5px" }}>All Categories</span>
              </Link>
              <span className="spacer">/</span>
              <span>Add Course</span>
            </S.Breadcrumbs>
          )}

          <S.Grid>
            <S.Form
              method="post"
              action="/"
              onSubmit={e => handleSubmit(e)}
              ref={formElement}
            >
              <S.FormRow2>
                <Select
                  name="categoryId"
                  label="Category"
                  reqd={true}
                  autocomplete="off"
                  data={[{ value: "", name: "--" }].concat(
                    data.categories.map(cat => {
                      return { value: cat.id, name: cat.name }
                    })
                  )}
                  update={handleUpdate}
                  errors={errors}
                  value={categoryId}
                />

                <div className="empty" />
              </S.FormRow2>

              <S.FormRow1>
                <Input
                  name="name"
                  label="Course Name"
                  reqd={true}
                  autocomplete="off"
                  update={handleUpdate}
                  errors={errors}
                />
              </S.FormRow1>

              <S.FormRow3>
                <Input
                  name="sponsor"
                  label="Sponsor"
                  reqd={true}
                  autocomplete="off"
                  update={handleUpdate}
                  errors={errors}
                />

                <Input
                  name="expires"
                  label="Expire Date"
                  reqd={true}
                  autocomplete="off"
                  update={handleUpdate}
                  errors={errors}
                />

                <Input
                  name="credit"
                  label="Credit General"
                  reqd={true}
                  autocomplete="off"
                  update={handleUpdate}
                  errors={errors}
                />

                <div className="empty" />
              </S.FormRow3>

              <S.FormMsgSubmit>
                {msg.type && <Msg data={msg} />}
                {msg.type !== "working" && (
                  <Submit name="Add Course" icon={faChevronCircleRight} />
                )}
              </S.FormMsgSubmit>
            </S.Form>
          </S.Grid>
        </Fragment>
      )}
    </S.SectionContainer>
  )
}

export default Add
